import React, { useState, useEffect } from "react";
import { useNovelCustomerPassDispatch, useNovelCustomerPassSelector } from "@customer-pass/redux/reduxHooks";
import { useAsyncCallback } from "@novel/shared/utils/useAsyncCallback";
import { createAndroidDevice } from "@customer-pass/redux/actionCreators/androidDevice";
import { SurfaceableError } from "@novel/shared/utils/SurfaceableError";
import { PreApprovalAndroid } from "@customer-pass/components/PreApprovalAndroid";
import { detectIncognito } from "@customer-pass/utils/isIncognito";
import { IncognitoScreen } from "@customer-pass/components/IncognitoScreen";
import { UnsupportedBrowserScreen } from "@customer-pass/components/UnsupportedBrowser";
import { checkIsChrome } from "@novel/shared/utils/userAgentUtils";
import { DetectAdblock } from "@scthakuri/adblock-detector";
export function InstallAndroidPassCmp(): JSX.Element | null {
  const dispatch = useNovelCustomerPassDispatch();
  const isChromeBrowser = useNovelCustomerPassSelector(state => !!state.passUi.isChromeBrowser);
  const authLink = useNovelCustomerPassSelector(state => state.auth.authLink);
  const serviceWorkerRegistration = useNovelCustomerPassSelector(state => state.auth.serviceWorkerRegistration);
  const [startDetect, setStartDetect] = useState(true);
  const [detected, setDetected] = useState(false);
  useEffect(() => {
    if (startDetect) {
      DetectAdblock(enable => {
        setDetected(enable);
        setStartDetect(false);
      });
    }
  }, [startDetect]);

  // pass install on Android only works on Chrome
  const isUnsupportedBrowser = typeof window !== "undefined" ? !checkIsChrome() || detected : false;
  const [isIncognito, setIsIncognito] = useState(false);
  const [allowing, setAllowing] = useState(false);
  const [userCancelledApproval, setUserCancelledApproval] = useState(false);
  const [notificationRequestState, setNotificationRequestState] = useState<"granted" | "denied" | "default">(typeof window !== "undefined" && "PushManager" in window ? Notification.permission : "default");
  const checkIsCognito = useAsyncCallback(async () => {
    const result = await detectIncognito();
    setIsIncognito(result.isPrivate);
    return !!result.isPrivate;
  }, []);
  useEffect(() => {
    checkIsCognito();
  }, [checkIsCognito]);
  const enableWebPushNotifications = useAsyncCallback(async () => {
    setAllowing(true);
    if (isChromeBrowser && serviceWorkerRegistration) {
      let permissionResult: "denied" | "granted" | "default" = notificationRequestState;

      // wait an extra three seconds to make sure they see the normal UI
      await new Promise(resolve => {
        setTimeout(resolve, 3000);
      });
      if (permissionResult === "default") {
        permissionResult = (await Notification.requestPermission() as "denied" | "granted" | "default");
      }
      if (permissionResult !== "granted" && permissionResult !== "denied") {
        // could happen if they close out of the notification request
        // should just make them click the button again
        setUserCancelledApproval(true);
        return;
      } else if (permissionResult === "denied") {
        // TODO: will need handle with "settings" flow
        setNotificationRequestState("denied");
        return;
      }
      const subscription = await serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY!
      });
      const result = await dispatch(createAndroidDevice(subscription, serviceWorkerRegistration));
      if (result.type === "ERROR_LOADING_RESOLVED_CUSTOMER") {
        throw new SurfaceableError(result.payload.errorMessage);
      }
      setNotificationRequestState("granted");
    }
    setAllowing(false);
  }, [isChromeBrowser, notificationRequestState, serviceWorkerRegistration]);
  useEffect(() => {
    // if not the correct browser, deep link redirect the customer to Chrome
    if (!isChromeBrowser) {
      if (authLink) {
        // deep link redirect to Chrome here
        window.open(`googlechrome://navigate?url=${authLink}`, "_blank");

        // close this tab in the background once redirect completes
        const ASSUMED_REDIRECT_TIME = 5000;
        setTimeout(() => {
          window.close();
        }, ASSUMED_REDIRECT_TIME);
      }
    } else if (notificationRequestState === "granted") {
      // note - this will only get run if the user has previously granted web push
      // permissions, otherwise we'll want the pre-approval screen below to show
      enableWebPushNotifications();
    }
  }, [isChromeBrowser, authLink, enableWebPushNotifications, notificationRequestState]);
  if (isUnsupportedBrowser) {
    return <UnsupportedBrowserScreen authLink={authLink} />;
  }
  if (isIncognito) {
    return <IncognitoScreen authLink={authLink} />;
  }
  const readyForInstall = serviceWorkerRegistration && isChromeBrowser;
  if (isChromeBrowser && readyForInstall && notificationRequestState !== "granted") {
    return <PreApprovalAndroid enableWebPushNotifications={enableWebPushNotifications} requiresSettingsChange={userCancelledApproval || notificationRequestState === "denied"} loading={allowing} />;
  }
  return null;
}